@import '../../styles/variables';
@import '../../styles/mixins';

.step {
    @include listitem-hover;

    > *::first-letter {
        text-transform: capitalize;
    }

    &--checked {
        color: $primary;
        text-decoration: line-through;
    }
}