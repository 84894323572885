@import '../../styles/variables';
@import '../../styles/breakpoints';
@import '../../styles/mixins';

.ingredient-line {
    $base: &;

    @include listitem-hover;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    
    @include media-breakpoint-down (lg) {
        margin-bottom: 0px;
    }

    &__image {
        max-height: 100%;
        max-width: 100%;
    }
    &__image-container {
        width: 30px;
        height: 30px;
        text-align: center;
    }

    &__text {
        margin-bottom: 0px;
        flex: 1;
        > span {
            padding-left: 10px;
            display: inline-block;
        }
    }

    &__details {
        color: $gray-600;
    }

    &--checked {
        color: $primary;
        
        #{$base}__image {
            filter: sepia(1);
        }

        span {
            position: relative;
        }
        span:not(#{$base}__details)::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $primary;
            left: 6px;
            top: 50%;
        }
        #{$base}__details {
            text-decoration: line-through;
        }
    }
}