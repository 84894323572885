.quantity-control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 160px;

    button {

        padding: 5px;

        svg {
            width: 20px;
            height: 20px;
            vertical-align: sub;
        }
    }

}