.container-small {
    @extend .container;
    @include media-breakpoint-up (md) {
        padding-right: $grid-gutter-width;
        padding-left: $grid-gutter-width;
        margin-right: auto;
        margin-left: auto;
        max-width: 700px;
    }
}

.mt-6 {
    margin-top: 3.75rem;
}

.mb-6 {
    margin-bottom: 3.75rem;
}