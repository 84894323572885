@import '../../styles/breakpoints';

.menu-item {
    $base: &;
    position: relative;
    letter-spacing: 1px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(md) {
        justify-content: center;
    }
    &__link {
        padding-left: 15px;
        padding-right: 15px;
        
        font-size: $font-size-base * 1.25;
        @include media-breakpoint-down(lg) {
            font-size: $font-size-base;
        }
        text-decoration: none;
        text-transform: uppercase;
        color: $body-color;
        &:focus {
            color: $secondary;
        }
        &--active {
            color: $primary;
        }
    }
    &__picto {
        max-height: 40px;
    }
    &__submenu {
        position: absolute;
        top: 100%;
        left: 0px;
        width: auto;
        min-width: 100%;
        z-index: 3;
        background-color: $white;
        border: 1px solid $gray-600;
        border-top: none;

        @include media-breakpoint-down(md) {
            display: none;
        }

        #{$base} {
            line-height: normal;
            width: 100%;
            padding-top: 8px;
            padding-bottom: 8px;
            
        }
        #{$base}__link {
            font-size: $font-size-base;
            line-height: normal;
            display: block;
        }
        
        transition: opacity 0.5s;
        opacity: 0;
        height: 0;
        overflow: hidden;
    }
    &:hover #{$base}__submenu {
        opacity: 1;
        height: auto;  
    }
}