@import '../../styles/breakpoints';

.recipe {
    &__head {
        margin-bottom: 2rem;
        @include media-breakpoint-down (lg) {
            margin-bottom: 0rem;
        }
    }
    &__image {
        max-width: 100%;
    }
    &__description {
        margin-bottom: 2rem;
        @include media-breakpoint-down (lg) {
            display: none;
        }
        &--mobile {
            display: none;   
            @include media-breakpoint-down (lg) {
                display: block;
                margin-top: 1.25rem !important;
            }
        }
    }
}