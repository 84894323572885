.go-to-top-button {
    position: fixed;
    right: 10px;
    bottom: 10px;
    padding: 10px 5px !important;
    width: 35px;
    height: 35px;
    svg {
        position: relative;
        top: -6px;
        width: 100%;
        height: 100%;
    }
}