@import "../../styles/breakpoints";

.logo {
    max-width: 1040px;
    margin: 0 auto;
    margin-top: 10px;
    a {
        display: block;
        max-width: 100%;
    }
    &__image {
        max-width: 100%;
        padding: 0 3.25rem;
        @include media-breakpoint-down(sm) {
            padding: 0 1.875rem;
        }
    }
}