// ----------------------------
// Override bootstrap variables
// ----------------------------

// Color system
$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #686868;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$grullo:  #A89790;
$palesilver:#C5BBB7;
$blue:    #0d6efd;
$granitegray:#5E666C;
$indigo:  #6610f2;
$purple:  #6f42c1;
$pink:    #d63384;
$red:     #dc3545;
$orange:  #fd7e14;
$yellow:  #ffc107;
$green:   #198754;
$dimgray:#62726C;
$teal:    #20c997;
$cyan:    #0dcaf0;

$colors: (
  "grullo":     $grullo,
  "palesilver": $palesilver,
  "blue":       $blue,
  "granitegray":$granitegray,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "dimgray":    $dimgray,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
);

$primary:       $grullo;
$secondary:     $dimgray;
$terciary:      $palesilver;
$quaternary:    $granitegray;
$success:       $dimgray;
$info:          $granitegray;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-900;

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "terciary":   $terciary,
  "quaternary": $quaternary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

// Font
$font-family-sans-serif:      'Caviar-Dreams', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-color:                  $black;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875;
$font-size-lg:                $font-size-base * 1.25;
$font-size-xl:                $font-size-base * 1.5;

$line-height-base:            1.5;
$line-height-sm:              1.25;
$line-height-lg:              2;

$h1-font-size:                $font-size-base * 1.75;
$h2-font-size:                $font-size-base * 1.5;
$h3-font-size:                $font-size-base * 1.25;
$h4-font-size:                $font-size-base * 1.125;
$h5-font-size:                $font-size-base * 1;
$h6-font-size:                $font-size-base;

$headings-margin-bottom:      30px;
$headings-font-weight:        700;
$headings-line-height:        1.2;

// Other
$border-radius: 6px;
$box-shadow: 4px 4px 4px rgba($black, .25);
$box-shadow-lg: 10px 10px 4px rgba($black, .25);

// Buttons
$btn-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.25);
$btn-border-radius: 6px;
$btn-link-color: $black;

// Grid
$grid-gutter-width: 20px;

// other variables
$card-title-bg: rgba(0,0,0, .5);
$ig-blue: $terciary; // ImageGallery main color