@import '../../styles/variables';

.footer {
    background-color: $primary;
    padding: 40px 0px;
    text-align: center;
    &__menu-item {
        color: $white;
        padding-right: 20px;
    }
    a {
        color: $white;
        &:hover {
            color: $gray-200;
        }
    }
}