@import '../../styles/variables';
@import '../../styles/breakpoints';

.not-found-page {
    background-color: $black;
    position: absolute;
    top: 0px;
    left: 0px;
    min-height: 100vh;
    width: 100%;
    overflow-y: auto;
    z-index: 2;

    display: flex;
    align-items: center;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__image {
        max-width: 100%;
        margin-bottom: 3.125rem;
        margin-top: 1.25rem;
        max-height: 60vh;

        @include media-breakpoint-down (md) {
            margin-bottom: 1.25rem;
        }
    }

    &__title {
        font-family: "Pokemon-Classic";
        font-size: $font-size-base * 2.5;
        color: $primary;
        text-transform: none;
        font-weight: normal;

        @include media-breakpoint-down (sm) {
            font-size: $font-size-base;
        }
    }

    &__button {
        font-size: $font-size-base * 2;
        text-decoration: none;
        margin-bottom: 1.25rem;
        @include media-breakpoint-down (sm) {
            font-size:  $font-size-sm;
        }
        &-image {
            width: 58px;
            padding-left: 1.25rem;
            
            @include media-breakpoint-down (sm) {
                width: 40px;
                padding-left: 1rem;
            }
        }
    }
}