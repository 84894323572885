@import '../../styles/variables';

.filter-group {
    text-align: center;
    text-transform: uppercase;
    padding: 0 2.625rem;
    &__title {
        font-size: $h2-font-size;
    }
    &__separator {
        text-align: center;
        margin-bottom: 20px;
        svg {
            max-width: 100%;
        }
    }
}