@import './variables';

@mixin card-hover-animation {
    position: relative;
    transition: all 0.3s ease-out;

    &::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        transition: all 0.3s ease-out;
        cursor: pointer;
        z-index: 2;
    }
    &:hover {
        transform: translate(-5px, -5px);
        box-shadow: $box-shadow-lg;
    }
    &:hover::before {
        background-color: rgba(168, 151, 144, 0.5);
    }
}

@mixin listitem-hover {
    transition: all 0.25s;
    
    &:hover {
        opacity: 0.5;
        cursor: pointer;
    }
}