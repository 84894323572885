@font-face {
    font-family: 'Caviar-Dreams';
    src:url('../fonts/caviar-dreams/Caviar-Dreams.ttf.woff') format('woff'),
        url('../fonts/caviar-dreams/Caviar-Dreams.ttf.svg#Caviar-Dreams') format('svg'),
        url('../fonts/caviar-dreams/Caviar-Dreams.ttf.eot'),
        url('../fonts/caviar-dreams/Caviar-Dreams.ttf.eot?#iefix') format('embedded-opentype'); 
        font-weight: normal;
        font-style: normal;
}

@font-face {
    font-family: 'Pokemon-Classic';
    src:url('../fonts/pokemon-classic/Pokemon-Classic.woff') format('woff'); 
    font-weight: normal;
    font-style: normal;
}

// default variables
@import 'variables';

// bootstrap imports

// --start override of bootstrap-grid.scss
$include-column-box-sizing: true !default;

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";

@import "../../node_modules/bootstrap/scss/mixins/lists";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../node_modules/bootstrap/scss/mixins/container";
@import "../../node_modules/bootstrap/scss/mixins/grid";
@import "../../node_modules/bootstrap/scss/mixins/utilities";

@import "../../node_modules/bootstrap/scss/vendor/rfs";

@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins/color-mode';
@import "../../node_modules/bootstrap/scss/root";

@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";

@import "../../node_modules/bootstrap/scss/utilities";
// Only use the utilities we need
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-get-multiple(
  $utilities,
  (
    "display",
    "order",
    "flex",
    "flex-direction",
    "flex-grow",
    "flex-shrink",
    "flex-wrap",
    "justify-content",
    "align-items",
    "align-content",
    "align-self",
    "margin",
    "margin-x",
    "margin-y",
    "margin-top",
    "margin-end",
    "margin-bottom",
    "margin-start",
    "negative-margin",
    "negative-margin-x",
    "negative-margin-y",
    "negative-margin-top",
    "negative-margin-end",
    "negative-margin-bottom",
    "negative-margin-start",
    "padding",
    "padding-x",
    "padding-y",
    "padding-top",
    "padding-end",
    "padding-bottom",
    "padding-start",
    "text-align",
  )
);

@import "../../node_modules/bootstrap/scss/utilities/api.scss";
// --end override of bootstrap-grid.scss
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/spinners';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/helpers/visually-hidden';
@import '../../node_modules/bootstrap/scss/reboot';


// overrides
@import 'overrides/btn';
@import 'overrides/type';

// others
@import 'layout';
@import "~react-image-gallery/styles/scss/image-gallery.scss";